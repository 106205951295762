<template>
  <main class="container">
    <br />

    <div class="row">
      <div class="col-md-12">
        <div class="card rounded-lg border border-dark">
          <div class="row">
            <div class="col-md-6">
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Symposium Honor Committee</h5>
                    <hr />
                    <ul>
                      <li class="card-text">
                        Dr. İsmail KOYUNCU, Rector, Istanbul Technical
                        University
                      </li>
                      <li class="card-text">
                        Dr. Mahmut AYDIN, Rector, Samsun University
                      </li>
                      <li class="card-text">
                        Volkan Mutlu COŞKUN, Director General, Turkish State
                        Meteorological Service
                      </li>
                      <li class="card-text">
                        Dr. Ahmet Duran ŞAHİN, Head, ITU Dept. of Meteorological
                        Engineering
                      </li>
                      <li class="card-text">
                        Fırat ÇUKURÇAYIR, Head, TMMOB Chamber of Meteorology
                        Engineers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Symposium Organizing Committee</h5>
                    <hr />
                    <ul>
                      <li class="card-text">
                        Dr. Ahmet ÖZTOPAL, Chair, Istanbul Technical University
                      </li>
                      <li class="card-text">
                        Dr. Bahtiyar EFE, Vice Chair, Samsun University
                      </li>
                      <li class="card-text">
                        Dr. Bihter DURNA, Vice Chair, Samsun University
                      </li>
                      <li class="card-text">
                        Sema ÇİL, Turkish State Meteorological Service
                      </li>
                      <li class="card-text">
                        Emel ÜNAL, TMMOB Chamber of Meteorology Engineers
                      </li>
                      <li class="card-text">
                        Melek AKIN, Istanbul Technical University
                      </li>
                      <li class="card-text">
                        Fatma Ekin HÜNER, ITU METEO IRS Team
                      </li>
                      <li class="card-text">
                        Abdurrahman DURMAZ, ITU METEO IRS Team
                      </li>
                      <li class="card-text">Esra KAÇAR, ITU METEO IRS Team</li>
                      <li class="card-text">
                        Sema GÜNEŞLİK, İTÜ METEO IRS Team
                      </li>
                      <li class="card-text">Yağmur UZUN, ITU METEO IRS Team</li>
                      <li class="card-text">
                        Halil KARAKAYA, ITU METEO CODING Team
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Invited Speakers</h5>
                    <hr />
                    <ul>
                      <li class="card-text">
                        Dr. Ali Nadir ARSLAN, Finnish Meteorological Institute,
                        FIN
                      </li>
                      <li class="card-text">
                        Dr. Aynur S. SORMAN, Eskisehir Technical University, TUR
                      </li>
                      <li class="card-text">
                        Dr. Daniele CASELLA, National Research Council of Italy, ITA
                      </li>
                      <li class="card-text">
                        Dr. İsmail YÜCEL, Middle East Technical University, TUR
                      </li>
                      <li class="card-text">
                        Dr. Neil I. FOX, University of Missouri, USA
                      </li>
                      <li class="card-text">
                        Dr. Samuel MILLER, Plymouth State University, USA
                      </li>
                      <li class="card-text">
                        Dr. Sante LAVIOLA, National Research Council of Italy,
                        ITA
                      </li>
                      <li class="card-text">
                        Dr. Semih EKERCIN, Necmettin Erbakan University, TUR
                      </li>
                      <li class="card-text">
                        Dr. Zuhal AKYÜREK, Middle East Technical University, TUR
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row my-5 mx-4">
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Symposium Scientific Committee</h5>
                    <hr />
                    <ul>
                      <li>
                        Dr. Abdullah KAHRAMAN, University of Newcastle, GBR
                      </li>
                      <li>
                        Dr. Ahmet ÖZTOPAL, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Ahmet Duran ŞAHİN, Istanbul Technical University,
                        TUR
                      </li>
                      <li>Dr. Ahmet Emre TEKELİ, Iğdır University, TUR</li>
                      <li>Dr. Ali DENİZ, Istanbul Technical University, TUR</li>
                      <li>
                        Dr. Ali Nadir ARSLAN, Finnish Meteorological Institute,
                        FIN
                      </li>
                      <li>Dr. Anthony LUPO, University of Missouri, USA</li>
                      <li>
                        Dr. Aynur Şensoy ŞORMAN, Eskişehir Technical University,
                        TUR
                      </li>
                      <li>Dr. Bahtiyar EFE, Samsun University, TUR</li>
                      <li>Dr. Bihter DURNA, Samsun University, TUR</li>
                      <li>
                        Dr. Ceyhan KÂHYA, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Daniele CASELLA, National Research Council of Italy,
                        ITA
                      </li>
                      <li>
                        Dr. Deniz DEMIRHAN, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Emrah Tuncay ÖZDEMİR, Turkish State Meteorological
                        Service, TUR
                      </li>
                      <li>Dr. Elçin TAN, Istanbul Technical University, TUR</li>
                      <li>
                        Dr. Emine Ceren K. EYİGÜLER, Istanbul Technical
                        University, TUR
                      </li>
                      <li>
                        Dr. Filiz TÜRK KATIRCIOĞLU, Istanbul Technical
                        University, TUR
                      </li>
                      <li>Dr. Hakan AKSU, Samsun University, TUR</li>
                      <li>
                        Dr. Hakan KOÇAK, Turkish State Meteorological Service,
                        TUR
                      </li>
                      <li>Dr. Haldun KARAN, TUBITAK MAM, TUR</li>
                      <li>
                        Dr. Hüseyin TOROS, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. İsmail YÜCEL, Middle East Technical University, TUR
                      </li>
                      <li>Dr. Kaan KALKAN, TUBITAK-UZAY, TUR</li>
                      <li>
                        Dr. Kasım KOÇAK, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Kurtulus ÖZTÜRK, Turkish State Meteorological
                        Service, TUR
                      </li>
                      <li>
                        Dr. Levent ŞAYLAN, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Mikdat KADIOĞLU, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Nebiye MUSAOĞLU, Istanbul Technical University, TUR
                      </li>
                      <li>Dr. Neil I. FOX, University of Missouri, USA</li>
                      <li>Dr. Samuel MILLER, Plymouth State University, USA</li>
                      <li>
                        Dr. Sante LAVIOLA, National Research Council of Italy,
                        ITA
                      </li>
                      <li>
                        Dr. Semih EKERCIN, Necmettin Erbakan University, TUR
                      </li>
                      <li>
                        Dr. Sevinç ASİLHAN, Istanbul Technical University, TUR
                      </li>
                      <li>Dr. Şeyda Tilev, Abu Dhabi Polytechnic, ARE</li>
                      <li>
                        Dr. Şinasi KAYA, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Tuğrul YILMAZ, Middle East Technical University, TUR
                      </li>
                      <li>
                        Dr. Yurdanur UNAL, Istanbul Technical University, TUR
                      </li>
                      <li>Dr. Zafer ASLAN, Istanbul Aydin University, TUR</li>
                      <li>
                        Dr. Zerefşan KAYMAZ, Istanbul Technical University, TUR
                      </li>
                      <li>
                        Dr. Zuhal AKYÜREK, Middle East Technical University, TUR
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Boards",
  components: {},
};
</script>

<style scoped></style>
